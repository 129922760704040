import * as React from 'react';
import css from './downloadPage.module.css';
import { Typography } from '@mui/material';

const step = 0;

function DownloadPage() {
    return (
        <div className={css.topPallete}>
            <Typography gutterBottom sx={{
                fontWeight:"bold", 
                fontSize: 22,
                maxWidth: '500px',
                paddingTop: '30vh',
                marginLeft: '20%',
                marginRight: '20%',
                textAlign: 'center'
            }}>
                Ayo Download Aplikasi Bunga Rawa Belong
            </Typography>
            <div style={{width: '100%', justifyContent: 'center', display: 'flex'}}>
                <img src='/adobe-express-qr-code.png' style={{
                    width: '300px',
                    textAlign: 'center',
                    alignSelf: 'center'
                }} />
            </div>
            <Typography gutterBottom sx={{
                fontWeight:"bold", 
                fontSize: 18,
                maxWidth: '500px',
                marginLeft: '20%',
                marginRight: '20%',
                textAlign: 'center'
            }}>
                Download APK
            </Typography>
        </div>
    );
}

export default DownloadPage;