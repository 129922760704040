import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: 'Dummy 1',
    imgPath:
      './d578937c-e67c-4e3e-9ab6-4b60790df109.jpg',
  },
  {
    label: 'Dummy 2',
    imgPath:
      './ff5d0f2f-6e71-423e-8f47-3e9e03884b19.jpg',
  },
  {
    label: 'Dummy 3',
    imgPath:
      './pexels-ariann-245285.jpg',
  },
  {
    label: 'Dummy 4',
    imgPath:
      './Rawabelong2.jpg',
  },
];

function HomeCarousel() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ maxWidth: 1, flexGrow: 1 }}>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  aspectRatio: 16/9,
                  display: 'block',
                  maxWidth: 1,
                  overflow: 'hidden',
                  width: '100%',
                  objectFit: 'cover',
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
    </Box>
  );
}

export default HomeCarousel;